import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Confirm from "../pages/Authentication/Confirm"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import Register2 from "../pages/Authentication/Register2"
import Register3 from "../pages/Authentication/Register3"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import SelectBranch from "../pages/Authentication/SelectBranch"
import ResetPassword from "../pages/Authentication/ResetPassword"
// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Dashboard

import Companies from "../pages/Companies/list"
import CompanyUsers from "../pages/Companies/users"

import Branches from "../pages/Branches/list"
import Plans from "../pages/Plan/list"
import AppForm from "../pages/AppForm/list"
import PlanForm from "../pages/Plan/form"
import SurveyForm from "../pages/Survey/form"
import OrientationForm from "../pages/Orientation/form"
import CompanyInfo from "../pages/CompanyInfo/list"
import CompanyQr from "../pages/CompanyQr/list"
import QrV2 from "../pages/CompanyQr/v2/qr-list"
import RequestForm from "../pages/RequestForm/list"
import Analytics from "../pages/Analytic/dashboard"
import AnalyticBranches from "../pages/Analytic/branches"
import AnalyticBranchTag from "../pages/Analytic/branchTag"
import Advertisement from "../pages/Advertisement/list"
import ApplicationApproval from "../pages/ApplicationApproval/list"
import PlanFinished from "../pages/Plan/finished"
import SurveyFinished from "../pages/Survey/finished"
import Sector from "../pages/Sector/index"
import AvmInfo from "../pages/AvmInfos/index"
import Status from "../pages/Status/index"
import Survey from "../pages/Survey/list"
import Orientation from "../pages/Orientation/list"
import Duty from "../pages/Duty/list"
import PlanFromOutside from "../pages/PlanFromOutside/list"
import PlanFromOutsideForm from "../pages/PlanFromOutside/form"
import DevDuty from "../pages/DevDuty/list"
import SoftSkills from "../pages/SoftSkills/index"
import ForeignLanguage from "../pages/ForeignLanguage/index"
import DevForeignLanguage from "../pages/DevForeignLanguage/list"
import DevProgram from "../pages/devProgram/list"
import Program from "../pages/program/index"
import BackUrl from "../pages/BackUrl/index"
import DevSectorForm from "../pages/DevSectorForm/list"
import SectorFrom from "../pages/SectorForm/index"
import DutyForm from "../pages/DutyForm/index"
import DevDutyForm from "../pages/DevDutyForm/list"
import DevWorkType from "../pages/DevWorkType/list"
import WorkType from "pages/WorkType/index"
import DevUser from "../pages/DevUser/list"
import DutiesForm from "../pages/DutiesForm/index"
import DevRequestReason from "../pages/DevRequestReason/list"
import RequestReason from "../pages/RequestReason/list"
import Report from "../pages/Report/list"
import LoginLog from "../pages/LoginLog/index"
import Index from "../pages/Authentication/Index"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },

  // //company
  { path: "/companies", component: Companies },
  { path: "/companies/users/:id", component: CompanyUsers },

  { path: "/companyInfo", component: CompanyInfo },

  //plan
  { path: "/plans", component: Plans },
  { path: "/requestForm", component: RequestForm },

  // Mybranch
  { path: "/branches", component: Branches },

  // Mybranch
  { path: "/branches/:id", component: Branches },

  // Sector
  { path: "/sector", component: Sector },

  //AppForm
  { path: "/appForms", component: AppForm },

  //AvmInfo
  { path: "/avmInfo", component: AvmInfo },

  //status
  { path: "/status", component: Status },

  //survey
 
  { path: "/surveys", component: Survey },
  
  //Orientation 
  { path: "/orientation", component: Orientation },

  //Advirtesement 
  { path: "/advertisement", component: Advertisement },

  //ApplicationApproval 
  { path: "/applicationApproval", component: ApplicationApproval },

   //Duty 
   { path: "/duty", component: Duty },

  //SoftSkill
  {path:"/softSkill", component:SoftSkills},

  //DevDuty 
  { path: "/devDuty", component: DevDuty },
   
  //Plan From Outside
  { path: "/planFromOutside", component: PlanFromOutside },

  //ForeignLanguage
  { path: "/foreignLanguage", component: ForeignLanguage },
  { path: "/devForeignLanguage", component: DevForeignLanguage },
  
  //program
  { path: "/devProgram", component: DevProgram },
  { path: "/program", component: Program },

  //BackUrl 
  { path: "/backUrl", component: BackUrl },
  
  //SectorForm
  { path: "/devSectorForm", component: DevSectorForm },
  { path: "/sectorFrom", component: SectorFrom },

  //DutyForm
  { path: "/devDutyForm", component: DevDutyForm },
  { path: "/dutyForm", component: DutyForm },
    
  //WorkType
  { path: "/devWorkType", component: DevWorkType },
  { path: "/workType", component: WorkType },

  //Analytic
  { path: "/analytics", component: Analytics },
  { path: "/analytics/branches", component: AnalyticBranches },
  { path: "/analytics/branch/qr/:branchid/:qrid/:tagid", component: AnalyticBranchTag },

  //Dev USer
  {path: "/DevUser", component: DevUser },

   //DutiesForm
   {path: "/dutiesForm", component: DutiesForm }, 

  //Dev USer
  {path: "/DevRequestReason", component: DevRequestReason },

  //RequestReason
  {path: "/requestReason", component: RequestReason },

  //Report
  {path: "/report", component: Report },

  //LoginLog
  {path:"/loginLog",component:LoginLog},
   
  
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/", component:Index},
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/confirm/:id/:hash", component: Confirm },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/register2/:id", component: Register2 },
  { path: "/register3/:id", component: Register3 },
  { path: "/selectBranch", component: SelectBranch },
  { path: "/changePassword/:id/:hash", component: ResetPassword },
  { path: "/ik/finished/:id", component: PlanFinished },
  { path: "/survey/finished/:id", component: SurveyFinished },
  { path: "/ik/:id", component: PlanForm },
  { path: "/survey/:id/:form", component: SurveyForm },
  { path: "/orientation/:id/:form", component: OrientationForm },
  { path: "/planFromOutside/:id/:form/:label/:newModel", component: PlanFromOutsideForm },  
  
  { path: "/avm/:id", component: CompanyQr },
  { path: "/v2/:id", component: QrV2 },
]

export { userRoutes, authRoutes }  
