import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Input,
  FormGroup, Label, TabContent,
  TabPane,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import InputMask from "react-input-mask"

import { Link, withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import Surveys from "../Survey/list"
import Orientation from "../Orientation/list"
import OrientationPeriod from "../Orientation/period"
import {
  getCompanies as onGetCompanies,
  deleteCompany as onDeleteCompany,
  addCompany as onAddCompany,
  updateCompany as onUpdateCompany,
  resetCompany as onResetCompany,
  updateCompanyQr as onUpdateCompanyQr,
  deleteCompanyQRPhoto as onDeleteCompanyQRPhoto,
  getSectors as onGetSectors,
  updateCompanyClass as onUpdateCompanyClass,
  getStatuss as onGetStatus
} from "store/actions"

import { isEmpty, map, update, valuesIn } from "lodash"
import moment from "moment"

import { AvForm, AvField } from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import Dropzone from "react-dropzone"
import { authorize, Role } from "./../../components/helpers/authorize"
import "./datatables.scss"
import classnames from "classnames"
import imgEmpty from "./../../assets/images/empty.png"
import config from "config"

const Companies = props => {
  const dispatch = useDispatch()

  const [selectedId, setSelectedId] = useState()
  const [selectedCompany, setSelectedCompany] = useState()
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_alert_qr, setconfirm_alert_qr] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [modal, setModal] = useState(false)
  const [qrTemplateModal, setQrTemplateModal] = useState(false)
  const [statusModal, setStatusModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedPosMachine, setSelectedPosMachine] = useState();
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [formStep, setFormStep] = useState(0)

  const [value, setValue] = useState()
  const [selectedFiles, setselectedFiles] = useState([])
  const [grades, setGrades] = useState([{
    grade: 1,
    id: 0
  },
  ])
  const { error, Companies, Sectors, Status } = useSelector(state => ({
    error: state.Companies?.error,
    Companies: state.Companies?.data,
    Sectors: state.Sector?.data,
    Status: state.Status?.data
  }))

  let user = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    dispatch(onGetStatus())
    dispatch(onGetCompanies())
    dispatch(onGetSectors())
  }, [dispatch, statusModal])

  useEffect(() => {
    setFormStep(0)
  }, [Companies])

  useEffect(() => {
    !isEmpty(Companies[0]?.grades) ?
      setGrades(Companies[0]?.grades) : null



  }, [Companies, dispatch, statusModal])

  const toggle = () => {
    setModal(!modal)
  }
  const qrTemplateToggle = () => {
    setQrTemplateModal(!qrTemplateModal)
  }
  const statusToggle = () => {
    setStatusModal(!statusModal)
  }
  const statusSpan = _status => {
    if (_status == "success") {
      return (
        <span className="text-success">
          <i className="fa fa-check me-1"></i>
          {props.t("Confirmed")}
        </span>
      )
    } else if (_status == "wait") {
      return (
        <span className="text-warning">
          <i className="fa fa-hourglass me-1"></i>
          {props.t("Waiting")}
        </span>
      )
    } else {
      return (
        <span className="text-danger">
          <i className="fa fa-times me-1"></i>
          {props.t("Rejected")}
        </span>
      )
    }
  }

  const columns = [
    {
      dataField: "photo",
      text: props.t("Photo"),
      sort: false,
      formatter: (cell, row) => (
        <div>
          {cell ? (
            <img
              src={config.baseImg + cell}
              onClick={() => {
                setSelectedCompany(row)

                setIsEdit(true)

                toggle()
              }}
              style={{ width: "75px", height: "75px", objectFit: "contain" }}
            />
          ) : (
            <img
              src={imgEmpty}
              onClick={() => {
                setSelectedCompany(row)

                setIsEdit(true)
                toggle()
              }}
              style={{ width: "75px", height: "75px", objectFit: "contain" }}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "name",
      text: props.t("Company Name"),
      sort: false,
    },
    {
      dataField: "verDaire",
      text: props.t("Tax Office Name"),
      sort: false,
    },
    {
      dataField: "verNo",
      text: props.t("Tax No"),
      sort: false,
    },
    {
      dataField: "userName",
      text: props.t("Contact User Name"),
      sort: false,
    },
    {
      dataField: "phone",
      text: props.t("Phone"),
      sort: false,
    },
    {
      dataField: "sector",
      text: props.t("sector"),
      sort: false,
      formatter: (cell, row) => <span>{cell?.name}</span>,
    },
    {
      dataField: "confirm",
      text: props.t("Status"),
      sort: false,
      formatter: (cell, row) => <div>{statusSpan(cell)}</div>,
    },
    {
      dataField: "status",
      text: props.t("Unit"),
      sort: false,
      formatter: (cell, row) => (
        <div>
          <Button
            color="primary"
            className="btn-rounded "
            onClick={() => {
              setSelectedCompany(row)


              setIsEdit(true)
              statusToggle()
            }}
          >
            {props.t("Unit")}
          </Button>
        </div>
      ),
    },
    {
      dataField: "qrTemplate",
      text: props.t("Qr Code Template"),
      sort: false,
      formatter: (cell, row) => (
        <div>
          <Button
            color="primary"
            className="btn-rounded "
            onClick={() => {
              setSelectedCompany(row)


              setIsEdit(true)
              qrTemplateToggle()
            }}
          >
            {props.t("Qr Code Template")}
          </Button>
        </div>
      ),
    },
    {
      dataField: "_id",
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => (
        <div>
          <Button
            color="primary"
            className="btn-rounded "
            onClick={() => {
              setSelectedCompany(row)

              setIsEdit(true)
              toggle()
            }}
          >
            {props.t("Examine")}
          </Button>
        </div>
      ),
    },
  ]
  const handleChangeInput = (i, e) => {

    const values = [...grades]
    values[i].id = e.target.value
    values[0].id = "Firmalar"
    values[0]._id = Status?.filter(x => x.name == "Firmalar")[0]?._id
    setGrades(values)
  }


  const handleAdd = (grade) => {
    setGrades([...grades, { grade: grade + 1, id: 0 }])
  }

  const handleSubtract = (i) => {
    const values = [...grades]
    values.splice(i, 1)
    setGrades([...values])
  }


  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: Companies ? Companies.length : 0, // replace later with size(Companies),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: Companies ? Companies.length : 0 },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  const handleValidCompanySubmit = (e, values) => {
    const updateCompany = {
      _id: selectedCompany._id,
      name: values.name,
      description: values["description"],
      verDaire: values["verDaire"],
      verNo: values["verNo"],
      userName: values["userName"],
      email: values["email"],
      phone: values["phone"],
      address: values["address"],
      photo: values["photo"],
      sector: values["sector"]
    }

    // update Company
    dispatch(onUpdateCompany(updateCompany, selectedFiles))

    toggle()
  }
  const handleValidCompanyClass = (e, values) => {
    const updateCompanyQr = {
      _id: selectedCompany._id,
      grades: JSON.stringify(grades),

    }

    // update Company
    dispatch(onUpdateCompanyClass(updateCompanyQr))


    statusToggle()
    location.reload()
    //setSelectedCompany(null)
  }
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  const handleValidCompanyQrSubmit = (e, values) => {
    const updateCompanyQr = {
      _id: selectedCompany._id,

      QRphoto: values["photo"],
    }

    // update Company
    dispatch(onUpdateCompanyQr(updateCompanyQr, selectedFiles))


    qrTemplateToggle()
    //setSelectedCompany(null)
  }

  //IMAGE CONTROL
  function handleAcceptedFiles(files) {
    files.map(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <MetaTags>
          <title>{props.t("My Company Info")}</title>
        </MetaTags>
        <Container fluid>
          <Card> 
            <CardBody style={{padding:"0.7rem 1.25rem"}}>
              {authorize(Role.Avm) ? (
                 <Row>
                 <Col sm="12">
                   <Breadcrumb
                     title={props.t("My Company Info")}
                     breadcrumbItem={props.t("My Company Info")}
                   />

                   <Card>
                     <CardBody>
                       <PaginationProvider
                         pagination={paginationFactory(pageOptions)}
                         keyField="id"
                         columns={columns}
                         data={Companies}
                       >
                         {({ paginationProps, paginationTableProps }) => (
                           <ToolkitProvider
                             keyField="_id"
                             columns={columns}
                             data={Companies}
                             search
                           >
                             {toolkitProps => (
                               <React.Fragment>
                                 <Row className="mb-2">
                                   <Col md="4">
                                     <div className="search-box me-2 mb-2 d-inline-block">
                                       <div className="position-relative">
                                         <SearchBar {...toolkitProps.searchProps} />
                                         <i className="bx bx-search-alt search-icon" />
                                       </div>
                                     </div>
                                   </Col>
                                 </Row>

                                 <Row>
                                   <Col xl="12">
                                     <div className="table-responsive">
                                       <BootstrapTable
                                         keyField={"_id"}
                                         responsive
                                         bordered={false}
                                         striped={false}
                                         defaultSorted={defaultSorted}
                                         classes={"table align-middle table-nowrap"}
                                         headerWrapperClasses={"thead-light"}
                                         {...toolkitProps.baseProps}
                                         {...paginationTableProps}
                                       />
                                     </div>
                                   </Col>
                                 </Row>

                                 <Row className="align-items-md-center mt-30">
                                   <Col className="inner-custom-pagination d-flex">
                                     <div className="d-inline">
                                       <SizePerPageDropdownStandalone
                                         {...paginationProps}
                                       />
                                     </div>
                                     <div className="text-md-right ms-auto">
                                       <PaginationListStandalone {...paginationProps} />
                                     </div>
                                   </Col>
                                 </Row>
                               </React.Fragment>
                             )}
                           </ToolkitProvider>
                         )}
                       </PaginationProvider>
                     </CardBody>
                   </Card>
                 </Col>
               </Row>
              ) : (
                <div>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" ,backgroundColor: "white"}}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="bx bx-buildings"></i>
                        </span>
                        <span className="d-none d-sm-block">{props.t("My Company Info")}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer",backgroundColor:"white" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="bx bx-clipboard"></i>
                        </span>
                        <span className="d-none d-sm-block">{props.t("Organizational Commitment")}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer",backgroundColor: "white" }}
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="bx bx-clipboard"></i>
                        </span>
                        <span className="d-none d-sm-block">{props.t("Orientation")}</span>
                      </NavLink>
                    </NavItem>

                  </Nav> 

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <Breadcrumb
                            title={props.t("My Company Info")}
                            breadcrumbItem={props.t("My Company Info")}
                          />

                          <Card>
                            <CardBody>
                              <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField="id"
                                columns={columns}
                                data={Companies}
                              >
                                {({ paginationProps, paginationTableProps }) => (
                                  <ToolkitProvider
                                    keyField="_id"
                                    columns={columns}
                                    data={Companies}
                                    search
                                  >
                                    {toolkitProps => (
                                      <React.Fragment>
                                        <Row className="mb-2">
                                          <Col md="4">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                              <div className="position-relative">
                                                <SearchBar {...toolkitProps.searchProps} />
                                                <i className="bx bx-search-alt search-icon" />
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col xl="12">
                                            <div className="table-responsive">
                                              <BootstrapTable
                                                keyField={"_id"}
                                                responsive
                                                bordered={false}
                                                striped={false}
                                                defaultSorted={defaultSorted}
                                                classes={"table align-middle table-nowrap"}
                                                headerWrapperClasses={"thead-light"}
                                                {...toolkitProps.baseProps}
                                                {...paginationTableProps}
                                              />
                                            </div>
                                          </Col>
                                        </Row>

                                        <Row className="align-items-md-center mt-30">
                                          <Col className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                              <SizePerPageDropdownStandalone
                                                {...paginationProps}
                                              />
                                            </div>
                                            <div className="text-md-right ms-auto">
                                              <PaginationListStandalone {...paginationProps} />
                                            </div>
                                          </Col>
                                        </Row>
                                      </React.Fragment>
                                    )}
                                  </ToolkitProvider>
                                )}
                              </PaginationProvider>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <Surveys /> 
                        </Col>
                      </Row>
                    </TabPane> 
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <Orientation /> 
                        </Col>
                        <Col sm="12">
              <OrientationPeriod/>
              </Col>
                      </Row>
                    </TabPane>

                  </TabContent>
                </div>
              )}

            </CardBody>
          </Card>
          {/* Render Breadcrumb */}

          {/* TABLE */}






          {/* ADD or EDIT */}
          <Modal isOpen={modal} toggle={toggle} size={"lg"}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? props.t("My Company Info") : props.t("Add Company")}
            </ModalHeader>
            <ModalBody>
              <AvForm
                onValidSubmit={handleValidCompanySubmit}
                autoComplete="off"
              >
                <Row form>
                  <Col xs={12}>
                    <div className="wizard clearfix mb-4">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: formStep === 0 })}
                          >
                            <NavLink
                              className={classnames({
                                current: formStep === 0,
                              })}
                              onClick={() => {
                                setFormStep(0)
                              }}
                            >
                              <span className="number">1</span>{" "}
                              {props.t("Company Info")}
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: formStep === 1 })}
                          >
                            <NavLink
                              className={classnames({
                                active: formStep === 1,
                              })}
                              onClick={() => {
                                setFormStep(1)
                              }}
                            >
                              <span className="number ms-2">2</span>{" "}
                              {props.t("Contact User Info")}
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                    </div>
                    <FormGroup row>
                      <div
                        id="step-0"
                        style={{ display: formStep == 0 ? "block" : "none" }}
                      >
                        <div
                          className="mb-3"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <div>
                            {selectedCompany?.photo ? (
                              <img
                                src={config.baseImg + selectedCompany?.photo}
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  objectFit: "contain",
                                }}
                              />
                            ) : (
                              <img
                                src={imgEmpty}
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  objectFit: "contain",
                                }}
                              />
                            )}
                          </div>
                          <div
                            style={{
                              width: "75%",
                              border: "1px solid #ced4da",
                              borderRadius: "10px",
                              height: "150px",
                            }}
                          >
                            <Dropzone
                              multiple={false}
                              accept="image/jpeg, image/png"
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone-min">
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div
                                      className="dz-message needsclick"
                                      style={{ padding: "0px" }}
                                    >
                                      <div>
                                        <i className="display-5 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>{props.t("Drop Image")}</h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews"
                              id="file-previews"
                              style={{
                                position: "absolute",
                                zIndex: 99,
                                left: 0,
                                top: -5,
                              }}
                            >
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <div className="align-items-center">
                                        <div className="col-12">
                                          <img
                                            data-dz-thumbnail=""
                                            style={{
                                              width: "150px",
                                              height: "120px",
                                              objectFit: "contain",
                                            }}
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <AvField
                            className="form-select"
                            type="select"
                            name="sector"
                            value={selectedCompany?.sector?._id}
                            label={props.t("Sector Type")}
                          >
                            <option>{props.t("Sector Type")}</option>
                            {Sectors?.map((item, index) => (
                              <option key={index} value={item._id} >{item.name}</option>
                            ))}
                          </AvField>
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="name"
                            label={props.t("Company Name")}
                            type="text"
                            disabled
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: true },
                            }}
                            value={selectedCompany?.name || ""}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="verDaire"
                            label={props.t("Tax Office Name")}
                            type="text"
                            disabled
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: false },
                            }}
                            value={selectedCompany?.verDaire || ""}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="verNo"
                            label={props.t("Tax No")}
                            type="text"
                            disabled
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: false },
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage:
                                  "Geçerli Bir Vergi Numarası Girin",
                              },
                            }}
                            value={selectedCompany?.verNo || ""}
                          />
                        </div>

                      </div>
                    </FormGroup>
                    <FormGroup row>
                      <div
                        id="step-1"
                        style={{ display: formStep == 1 ? "block" : "none" }}
                      >
                        <div className="mb-3">
                          <small>
                            {props.t("Company Generate Contact User Info")}
                          </small>
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="userName"
                            label={props.t("User Name")}
                            type="text"
                            disabled
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: false },
                            }}
                            value={selectedCompany?.userName || ""}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label={props.t("Email")}
                            type="text"
                            disabled
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: false },
                            }}
                            value={selectedCompany?.email || ""}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="phone"
                            label={props.t("Phone")}
                            type="text"
                            disabled
                            errorMessage={props.t("InvalidInput")}
                            mask="(999) 999-9999"
                            maskChar="-"
                            tag={[Input, InputMask]}
                            placeholder="(999) 999-9999"
                            validate={{
                              required: { value: false },
                            }}
                            value={selectedCompany?.phone || ""}
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        className="me-2"
                        onClick={() => {
                          toggle()
                        }}
                      >
                        {props.t("Cancel")}
                      </Button>

                      {formStep > 0 ? (
                        <Button
                          color="primary"
                          className="btn btn-primary me-2 "
                          onClick={() => {
                            setFormStep(formStep - 1)
                          }}
                        >
                          {props.t("Prev")}
                        </Button>
                      ) : null}

                      {formStep < 1 ? (
                        <Button
                          color="primary"
                          className="btn btn-primary me-2 "
                          onClick={() => {
                            setFormStep(formStep + 1)
                          }}
                        >
                          {props.t("Next")}
                        </Button>
                      ) : null}
                      {formStep == 1 ? (
                        <Button
                          type="submit"
                          className="btn-success me-2"
                          onClick={() => { }}
                        >
                          {props.t("Save")}
                        </Button>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>

          <Modal isOpen={qrTemplateModal} toggle={qrTemplateToggle} size={"lg"}>
            <ModalHeader toggle={qrTemplateToggle} tag="h4">
              {!!isEdit
                ? props.t("Edit Qr Code Template")
                : props.t("Add Qr Code Template")}
            </ModalHeader>
            <ModalBody>
              <AvForm
                onValidSubmit={handleValidCompanyQrSubmit}
                autoComplete="off"
              >
                <Row form>
                  <Col xs={12}>
                    <FormGroup row>
                      <div
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <div>
                          {selectedCompany?.QRphoto ? (
                            <img
                              src={config.baseImg + selectedCompany?.QRphoto}
                              style={{
                                width: "150px",
                                height: "150px",
                                objectFit: "contain",
                              }}
                            />
                          ) : (
                            <img
                              src={imgEmpty}
                              style={{
                                width: "150px",
                                height: "150px",
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            width: "75%",
                            border: "1px solid #ced4da",
                            borderRadius: "10px",
                            height: "150px",
                          }}
                        >
                          <Dropzone
                            multiple={false}
                            accept="image/jpeg, image/png"
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(acceptedFiles)
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone-min">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div
                                    className="dz-message needsclick"
                                    style={{ padding: "0px" }}
                                  >
                                    <div>
                                      <i className="display-5 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>{props.t("Drop Image")}</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews"
                            id="file-previews"
                            style={{
                              position: "absolute",
                              zIndex: 99,
                              left: 0,
                              top: -5,
                            }}
                          >
                            {selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <div className="align-items-center">
                                      <div className="col-12">
                                        <img
                                          data-dz-thumbnail=""
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            objectFit: "contain",
                                          }}
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">




                      <Button
                        type="submit"
                        className="btn-success me-2"
                        onClick={() => { }}
                      >
                        {props.t("Save")}
                      </Button>
                      {!isEmpty(selectedCompany?.QRphoto) ? <Button
                        className="me-2 btn-danger"
                        onClick={() => {
                          setSelectedId(selectedCompany?._id)
                          setconfirm_alert_qr(true);
                        }}
                      >
                        {props.t("Delete")}
                      </Button> : null}
                      <Button
                        className="me-2"
                        onClick={() => {
                          qrTemplateToggle()
                        }}
                      >
                        {props.t("Cancel")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>

          <Modal isOpen={statusModal} toggle={statusToggle} size={"lg"}>
            <ModalHeader toggle={statusToggle} tag="h4">
              {props.t("Sınıf Belirleme")}
            </ModalHeader>
            <ModalBody>
              <AvForm
                onValidSubmit={handleValidCompanyClass}
                autoComplete="off"
              >
                <Row form>
                  <Col xs={12}>
                    <Row form>
                      <Col xs={12} className="pb-4">

                        {grades?.map((item, i) => (
                          <div key={i}>
                            <Row className="mt-2">
                              <Col md="4" className="text-end">
                                <Label
                                  md="8"
                                  className="col-form-label"
                                >
                                  {i == 0 ? props.t("1.Birim") : item.grade + " " + props.t("Birim")}

                                </Label>
                              </Col>
                              <Col md="4">
                                <AvField
                                  className="form-select"
                                  type="select"
                                  name="sector"
                                  disabled={i == 0 ? true : false}
                                  value={i == 0 ? (
                                    // Status?.filter(x => x.name == "Firmalar")[0]?._id
                                    "Firmalar"
                                  ) : selectedCompany?.grades[i]?.id}

                                  onChange={e => handleChangeInput(i, e)}
                                >
                                  <option>{props.t("Birim Seç")}</option>
                                  {Status?.map((item, index) => (
                                    <option key={index} value={item.id} >{item.name}</option>
                                  ))}
                                </AvField>

                              </Col>
                              <Col md="4">
                                {i == grades.length - 1 ?
                                  <div>
                                    <Button onClick={() => handleAdd(item.grade)} className="btn btn-success me-2">
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                    {grades.length > 1 ? <Button onClick={() => handleSubtract(i)}>
                                      <i className="fas fa-minus"></i>
                                    </Button> : null}
                                  </div> : null
                                }
                              </Col>


                            </Row>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        type="submit"
                        className="btn-success me-2"
                        onClick={() => { }}
                      >
                        {props.t("Save")}
                      </Button>
                      <Button
                        className="me-2"
                        onClick={() => {
                          statusToggle()
                        }}
                      >
                        {props.t("Cancel")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>

          {success_dlg ? (
            <SweetAlert
              success
              title={dynamic_title}
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {confirm_alert ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Delete")}
              onConfirm={() => {
                setconfirm_alert(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Deleted"))
                setdynamic_description("")
                dispatch(onDeleteCompany(selectedId))
                setSelectedId(null)
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}

          {confirm_alert_qr ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Delete")}
              onConfirm={() => {
                setconfirm_alert_qr(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Deleted"))
                setdynamic_description("")
                dispatch(onDeleteCompanyQRPhoto(selectedId))
                setSelectedId(null)
                setSelectedCompany(null)
                qrTemplateToggle()
              }}
              onCancel={() => setconfirm_alert_qr(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}

          {error && error ? (
            <SweetAlert
              title={props.t("Error")}
              warning
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                dispatch(onResetCompany())
              }}
            >
              {JSON.stringify(error)}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Companies))
